import React from 'react'
import './RaiseQueryModal.css'
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import classNames from "classnames";
import PhoneInput from 'react-phone-input-2';

const RaiseQueryModal = (props) => {

    const {
        control,
        register,
        handleSubmit,
        setError,
        getValues,
        setValue,
        reset,
        formState: { errors },
      } = useForm();

    const onSubmit = async (d) => {

    }

    return (
        <div className='RaiseQueryModal'>
            <Modal
                className="RaiseQueryModal_Modal"
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>Raise a Query</Modal.Header>
                <Modal.Body>
                    <div className='form_div'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group mb-3">
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter Name"
                                    {...register("name", {
                                        required: "Name required",
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.name,
                                        "is-valid": getValues("name"),
                                    })}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email"
                                    {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            message: "Invalid email address",
                                        },
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.email,
                                        "is-valid": getValues("email"),
                                    })}
                                />
                            </div>
                            <div className="form-group mb-3">

                                <Controller
                                    name="contact_no"
                                    control={control}
                                    render={({ field }) => (
                                        <div style={{ position: "relative" }}>
                                            <PhoneInput
                                                country="in"
                                                name="contact_no"
                                                value={field.value}
                                                onChange={(value) => field.onChange(value)}
                                                inputStyle={{
                                                    borderColor: errors.contact_no
                                                        ? "red"
                                                        : getValues("contact_no")
                                                            ? "green"
                                                            : "",
                                                }}
                                            />

                                        </div>
                                    )}
                                   
                                />
                            </div>

                            <div className="form-group mb-3">
                                <FloatingLabel
                                    controlId="floatingTextarea2"
                                    label="Enter your query"
                                >
                                    <Form.Control
                                        as="textarea"
                                        type="text"
                                        name="query"
                                        placeholder="Leave a query here"
                                        {...register("query", {
                                            required: "Query required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.query,
                                            "is-valid": getValues("query"),
                                        })}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="form-group mb-3">
                                <div className="btn_holder">
                                    <button className="submit_btn">Submit</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RaiseQueryModal
