import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, Select2Data, IMG_URL, getDimension } =
    useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = useForm();
  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("country_id", data?.country_id?.value);
      DataToSend.append("state_id", data?.state_id?.value);
      DataToSend.append("city_id", data?.city_id?.value);
      DataToSend.append(
        "language_id",
        JSON.stringify(data.language_id.map((module) => module.value))
      );
      DataToSend.append("job_type_id", data?.job_type_id?.value);
      DataToSend.append("department_id", data?.department_id?.value);
      DataToSend.append("qualification_id", data?.qualification_id?.value);
      DataToSend.append("name", data?.name);
      DataToSend.append("exp_from", data?.exp_from);
      DataToSend.append("exp_to", data?.exp_to);
      DataToSend.append("job_responsibilities", data?.job_responsibilities);
      DataToSend.append("requirement", data?.requirement);

      DataToSend.append("hr_name", data?.hr_name);
      DataToSend.append("vacancy", data?.vacancy);
      DataToSend.append("deadline", data?.deadline);
      DataToSend.append("company_name", data?.company_name);
      DataToSend.append("recruiter_email", data?.recruiter_email);
      DataToSend.append("pay_from", data?.pay_from);
      DataToSend.append("pay_to", data?.pay_to);
      DataToSend.append("skills", data?.skills);
      DataToSend.append("recruiter_contact", data?.recruiter_contact);
      DataToSend.append("image", data?.image[0]);
      const response = await postData(`/career/jobs/${id}`, DataToSend);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/career/jobs/${id}`);
    reset(response?.data);
    setImagePreview(IMG_URL + response?.data?.image);
  };

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [workModule, setWorkModule] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [department, setDepartment] = useState([]);

  const GetAllCountry = async () => {
    const response = await getData("/allcountry");

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllState = async (id) => {
    const response = await getData(`/allstate/${id}`);

    if (response?.success) {
      setState(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCity = async (id) => {
    const response = await getData(`/allcity/${id}`);

    if (response?.success) {
      setCity(await Select2Data(response?.data, "city_id"));
    }
  };

  const GetAllWorkModule = async () => {
    const response = await getData(`/all-work-module`);

    if (response?.success) {
      setWorkModule(await Select2Data(response?.data, "work_module_id"));
    }
  };

  const GetAllJobType = async () => {
    const response = await getData(`/all-job-type`);

    if (response?.success) {
      setJobType(await Select2Data(response?.data, "job_type_id"));
    }
  };

  const GetAllQualification = async () => {
    const response = await getData(`/all-qualification`);

    if (response?.success) {
      setQualification(await Select2Data(response?.data, "qualification_id"));
    }
  };

  const GetAllDepartment = async () => {
    const response = await getData(`/all-department`);

    if (response?.success) {
      setDepartment(await Select2Data(response?.data, "department_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
    GetAllState();
    GetAllCity();
    GetAllWorkModule();
    GetAllJobType();
    GetAllQualification();
    GetAllDepartment();
    GetEditData();
  }, []);
  const expFrom = watch("exp_from");
  const expTo = watch("exp_to");

  const packFrom = watch("pay_from");
  const packTo = watch("pay_to");

  const [imagePreview, setImagePreview] = useState(null); // State to store image preview

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set image preview
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Jobs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Country</Form.Label>
                    <Controller
                      name="country_id" // name of the field
                      {...register("country_id", {
                        required: "Select Country",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.country_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={country}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllState(selectedOption.value);
                            setValue("country_id", selectedOption);
                            setValue("state_id", "");
                            setValue("city_id", "");
                          }}
                        />
                      )}
                    />
                    {errors.country_id && (
                      <span className="text-danger">
                        {errors.country_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>State</Form.Label>
                    <Controller
                      name="state_id" // name of the field
                      {...register("state_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={state}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                            GetAllCity(selectedOption.value);
                            setValue("state_id", selectedOption);
                            setValue("city_id", "");
                          }}
                        />
                      )}
                    />
                    {errors.state_id && (
                      <span className="text-danger">
                        {errors.state_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>City</Form.Label>
                    <Controller
                      name="city_id"
                      {...register("city_id", {
                        required: "Select City",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.city_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={city}
                        />
                      )}
                    />
                    {errors.city_id && (
                      <span className="text-danger">
                        {errors.city_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder=" Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Experience From</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          min="0"
                          name="exp_from"
                          placeholder=" Experience From"
                          className={classNames("", {
                            "is-invalid": errors?.exp_from,
                          })}
                          {...register("exp_from", {
                            required: "Experience From is required",
                            validate: (value) =>
                              value >= 0 || "Experience cannot be negative",
                          })}
                          onKeyDown={(e) => {
                            if (
                              e.key === "-" ||
                              e.key === "e" ||
                              e.key === "+"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </InputGroup>
                      {errors.exp_from && (
                        <span className="text-danger">
                          {errors.exp_from.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Experience To</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          min="0"
                          name="exp_to"
                          placeholder=" Experience To"
                          className={classNames("", {
                            "is-invalid": errors?.exp_to,
                          })}
                          {...register("exp_to", {
                            required: "Experience To is required",
                            validate: {
                              positive: (value) =>
                                value >= 0 || "Experience cannot be negative",
                              greaterThanExpFrom: (value) =>
                                value > parseFloat(expFrom) ||
                                "Experience To must be greater than Experience From",
                            },
                          })}
                          onKeyDown={(e) => {
                            if (
                              e.key === "-" ||
                              e.key === "e" ||
                              e.key === "+"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </InputGroup>
                      {errors.exp_to && (
                        <span className="text-danger">
                          {errors.exp_to.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Package From</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="pay_from"
                          placeholder=" Packege From"
                          className={classNames("", {
                            "is-invalid": errors?.pay_from,
                          })}
                          {...register("pay_from", {
                            required: "Packege From is required",
                          })}
                        />
                      </InputGroup>
                      {errors.pay_from && (
                        <span className="text-danger">
                          {errors.pay_from.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Package To</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="pay_to"
                          placeholder=" Packege To"
                          className={classNames("", {
                            "is-invalid": errors?.pay_to,
                          })}
                          {...register("pay_to", {
                            required: "Packege To is required",
                            validate: {
                              positive: (value) =>
                                value >= 0 || "Package cannot be negative",
                              greaterThanExpFrom: (value) =>
                                value > parseFloat(packFrom) ||
                                "Package To must be greater than Package From",
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.pay_to && (
                        <span className="text-danger">
                          {errors.pay_to.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Vacancy</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="vacancy"
                          placeholder=" vacancy"
                          className={classNames("", {
                            "is-invalid": errors?.vacancy,
                          })}
                          {...register("vacancy", {
                            required: "vacancy  is required",
                          })}
                        />
                      </InputGroup>
                      {errors.vacancy && (
                        <span className="text-danger">
                          {errors.vacancy.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Deadline</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="date"
                          name="deadline"
                          placeholder=" deadline"
                          className={classNames("", {
                            "is-invalid": errors?.deadline,
                          })}
                          {...register("deadline", {
                            required: "deadline  is required",
                          })}
                        />
                      </InputGroup>
                      {errors.deadline && (
                        <span className="text-danger">
                          {errors.deadline.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder=" company_name"
                          className={classNames("", {
                            "is-invalid": errors?.company_name,
                          })}
                          {...register("company_name", {
                            required: "company_name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.company_name && (
                        <span className="text-danger">
                          {errors.company_name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Hr Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder=" hr_name"
                          className={classNames("", {
                            "is-invalid": errors?.hr_name,
                          })}
                          {...register("hr_name", {
                            required: "hr_name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.hr_name && (
                        <span className="text-danger">
                          {errors.hr_name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Recruiter Email</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder=" recruiter_email"
                          className={classNames("", {
                            "is-invalid": errors?.recruiter_email,
                          })}
                          {...register("recruiter_email", {
                            required: "recruiter_email is required",
                            pattern: {
                              value:
                                /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                              message: "Invalid email address",
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.recruiter_email && (
                        <span className="text-danger">
                          {errors.recruiter_email.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label>Recruiter Contact</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder=" recruiter_contact"
                          className={classNames("", {
                            "is-invalid": errors?.recruiter_contact,
                          })}
                          {...register("recruiter_contact", {
                            required: "recruiter_contact is required",
                            pattern: {
                              value: /^\d{10}$/,
                              message: "Phone number must be exactly 10 digits",
                            },
                          })}
                          onKeyDown={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                      </InputGroup>
                      {errors.recruiter_contact && (
                        <span className="text-danger">
                          {errors.recruiter_contact.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              {/* 
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Company Logo</Form.Label>
                    </div>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          // required: "Image is required",
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col className="main-form-section my-3">
                <div className="main-form-section my-3">
                  <Form.Label> Image Preview</Form.Label>

                  <Col sm={9}>
                    {typeof getValues("image") == "string" ? (
                      <div className="image-preview-container">
                        <img
                          src={IMG_URL + getValues("image")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "150px", height: "130px" }}
                        />
                      </div>
                    ) : (
                      imageFile &&
                      imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )
                    )}
                  </Col>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Image(Image width and height must be 30  px and 20  px)</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          validate: async (value) => {
                            if (typeof value !== "string") {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              const fileType = value[0].name?.split(".")[1];

                              if (!fileTypes.includes(fileType)) {
                                return `please upload a valid file format. (${fileTypes})`;
                              }

                              const sizes = await getDimension(value[0]);
                              if (sizes.width !== 200 && sizes.height !== 260) {
                                return "Image width and height must be 200 px and 260 px";
                              }

                              // const fileSize = Math.round(
                              //   value[0].size / 1024
                              // );
                              // if (fileSize > 500) {
                              //   return "file size must be lower than 500kb";
                              // }
                            }
                          },
                        })}
                        onChange={handleImageChange}
                        accept=".jpg, .jpeg, .png"
                        multiple={false}
                      />
                    </InputGroup>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Form.Group>
                  {imagePreview && (
                    <div className="mt-2">
                      <img
                        src={imagePreview}
                        alt="Category Preview"
                        style={{ maxWidth: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Language</Form.Label>
                    <Controller
                      name="language_id"
                      {...register("language_id", {
                        required: "Select language",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          isMulti
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.language_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={workModule}
                        />
                      )}
                    />
                    {errors.language_id && (
                      <span className="text-danger">
                        {errors.language_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Job Type</Form.Label>
                    <Controller
                      name="job_type_id"
                      {...register("job_type_id", {
                        required: "Select Job Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.job_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={jobType}
                        />
                      )}
                    />
                    {errors.job_type_id && (
                      <span className="text-danger">
                        {errors.job_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Department</Form.Label>
                    <Controller
                      name="department_id"
                      {...register("department_id", {
                        required: "Select Deparment",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.department_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={department}
                        />
                      )}
                    />
                    {errors.department_id && (
                      <span className="text-danger">
                        {errors.department_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Qualification</Form.Label>
                    <Controller
                      name="qualification_id"
                      {...register("qualification_id", {
                        required: "Select Deparment",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.qualification_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={qualification}
                        />
                      )}
                    />
                    {errors.qualification_id && (
                      <span className="text-danger">
                        {errors.qualification_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col sm={12}>
                <Form.Label className="text-center">
                  Job Responsibilities
                </Form.Label>
                <Form.Group>
                  <Controller
                    name="job_responsibilities" // Provide the field name
                    control={control} // Pass the control object from useForm()
                    rules={{ required: "Job Responsibilities is required" }} // Validation rules
                    render={({ field }) => (
                      <JoditEditor
                        value={field?.value}
                        onChange={(newContent) => field.onChange(newContent)}
                        onBlur={field.onBlur}
                        className={classNames("", {
                          "is-invalid": !!errors.job_responsibilities,
                        })}
                        placeholder="Job Responsibilities"
                      />
                    )}
                  />
                </Form.Group>
                {errors.job_responsibilities && (
                  <span className="text-danger">
                    {errors.job_responsibilities.message}
                  </span>
                )}
              </Col>

              <Col sm={12}>
                <Form.Label className="text-center">Job Skills</Form.Label>
                <Form.Group>
                  <Controller
                    name="skills" // Provide the field name
                    control={control} // Pass the control object from useForm()
                    rules={{ required: "Job skills is required" }} // Validation rules
                    render={({ field }) => (
                      <JoditEditor
                        value={field?.value}
                        onChange={(newContent) => field.onChange(newContent)}
                        onBlur={field.onBlur}
                        className={classNames("", {
                          "is-invalid": !!errors.skills,
                        })}
                        placeholder="Job skills"
                      />
                    )}
                  />
                </Form.Group>
                {errors.skills && (
                  <span className="text-danger">{errors.skills.message}</span>
                )}
              </Col>

              <Col sm={12}>
                <Form.Label className="text-center">Requirement</Form.Label>
                <Form.Group>
                  <Controller
                    name="requirement" // Provide the field name
                    control={control} // Pass the control object from useForm()
                    rules={{ required: "Requirement is required" }} // Validation rules
                    render={({ field }) => (
                      <JoditEditor
                        value={field?.value}
                        onChange={(newContent) => field.onChange(newContent)}
                        onBlur={field.onBlur}
                        className={classNames("", {
                          "is-invalid": !!errors.requirement,
                        })}
                        placeholder="Requirement"
                      />
                    )}
                  />
                </Form.Group>
                {errors.requirement && (
                  <span className="text-danger">
                    {errors.requirement.message}
                  </span>
                )}
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
