import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, Select2Data, getDimension } =
    useContext(Context);
  const [websitetype, setwebsiteType] = useState([]);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/masters/category/${id}`);
    reset(response?.data);
    setImagePreview(IMG_URL + response?.data?.image);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("name", data?.name);
      sendData.append("sort_order", data?.sort_order);
      sendData.append("increase_percentage", data?.increase_percentage);
      sendData.append("website_type_id", data?.website_type_id?.value);
      sendData.append("image", data?.image[0]);
      const response = await postData(`/masters/category/${id}`, sendData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [imagePreview, setImagePreview] = useState(null); // State to store image preview

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set image preview
      };
      reader.readAsDataURL(file);
    }
  };

  const GetAllWebsiteType = async () => {
    const response = await getData("/allwebsitetype");

    if (response?.success) {
      setwebsiteType(await Select2Data(response?.data, "website_type_id"));
    }
  };

  useEffect(() => {
    GetAllWebsiteType();
  }, []);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Website Type</Form.Label>
                    <Controller
                      name="website_type_id" // name of the field
                      {...register("website_type_id", {
                        required: "Select Website Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.website_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={websitetype}
                        />
                      )}
                    />
                    {errors.website_type_id && (
                      <span className="text-danger">
                        {errors.website_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Category</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Category"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Increase Percentage</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="Increase Percentage"
                        placeholder="increase_percentage"
                        className={classNames("", {
                          "is-invalid": errors?.increase_percentage,
                        })}
                        {...register("increase_percentage", {
                          required: "Increase percentage is required",
                          pattern: {
                            value: /^[0-9]*\.?[0-9]+$/, // Regular expression to allow numbers and decimals
                            message: "Please enter a valid number",
                          },
                        })}
                        inputMode="decimal"
                        onKeyDown={(e) => {
                          // Allow numbers, backspace, delete, and navigation keys
                          if (
                            !(
                              (
                                (e.key >= "0" && e.key <= "9") || // Numbers
                                e.key === "Backspace" || // Backspace
                                e.key === "Delete" || // Delete
                                e.key === "ArrowLeft" || // Left arrow
                                e.key === "ArrowRight"
                              ) // Right arrow
                            )
                          ) {
                            e.preventDefault(); // Prevent invalid keypress
                          }
                        }}
                      />
                    </InputGroup>
                    {errors.increase_percentage && (
                      <span className="text-danger">
                        {errors.increase_percentage.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Sort Order</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="name"
                        placeholder="Sort Order"
                        className={classNames("", {
                          "is-invalid": errors?.sort_order,
                        })}
                        {...register("sort_order", {
                          required: "sort_order is required",
                        })}
                      />
                    </InputGroup>
                    {errors.sort_order && (
                      <span className="text-danger">
                        {errors.sort_order.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Image</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          validate: async (value) => {
                            if (typeof value !== "string") {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              const fileType = value[0].name?.split(".")[1];

                              if (!fileTypes.includes(fileType)) {
                                return `please upload a valid file format. (${fileTypes})`;
                              }

                              const sizes = await getDimension(value[0]);
                              if (sizes.width !== 420 && sizes.height !== 580) {
                                return "Image width and height must be 420 px and 580 px";
                              }

                              // const fileSize = Math.round(
                              //   value[0].size / 1024
                              // );
                              // if (fileSize > 500) {
                              //   return "file size must be lower than 500kb";
                              // }
                            }
                          },
                        })}
                        onChange={handleImageChange}
                        accept=".jpg, .jpeg, .png"
                        multiple={false}
                      />
                    </InputGroup>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Form.Group>
                  {imagePreview && (
                    <div className="mt-2">
                      <img
                        src={imagePreview}
                        alt="Category Preview"
                        style={{ maxWidth: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
